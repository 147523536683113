import ThemisNotificationSnackbar from "@/components/shared/notification-snackbar"
import { mapGetters, mapActions, mapMutations } from "vuex"
import SpeakUpLogo from "@/assets/images/speakup-logo.svg"
import SpeakUpLogoWithText from "@/assets/images/speakup-logo-with-text.svg"
import ThemisLanguageSelection from "@/components/language-selection"
import ThemisDecision from "@/components/shared/decision"
import ThemisSurvey from "@/components/shared/survey"
import { SHOW_REPORTER_FEEDBACK } from "@/constants"

export default {
  name      : "Default",
  components: { ThemisNotificationSnackbar, ThemisLanguageSelection, ThemisDecision, ThemisSurvey },
  data() {
    return {
      module                  : null,
      drawer                  : true,
      mini                    : this.$vuetify.breakpoint.smAndDown,
      showLanguagesDialog     : false,
      showEndSessionDialog    : false,
      showSurvey              : false,
      showDiscardMessageDialog: false,
      channelLanguages        : new Array(),
      surveySent              : false,
      showReporterFeedback    : SHOW_REPORTER_FEEDBACK
    }
  },
  computed: {
    ...mapGetters({
      isPageFreezed                      : "shared/isPageFreezed",
      isNotified                         : "shared/isNotified",
      breadcrumbs                        : "shared/breadcrumbs",
      notification                       : "shared/notification",
      channels                           : "channels/channels",
      languages                          : "languages/languages",
      translationConfigurationsOfChannels: "channels/translationConfigurationsOfChannels",
      reporterLanguage                   : "auth/reporterLanguage",
      isMessageRemoved                   : "messages/isMessageRemoved",
      isRemovingMessage                  : "messages/isRemovingMessage",
      report                             : "auth/report",
      reportFormValues                   : "reports/reportFormValues",
      isSurveyAdded                      : "survey/isSurveyAdded",
      isAddingSurvey                     : "survey/isAddingSurvey",
      isConsentGiven                     : "reports/isConsentGiven",
      privacyPolicyTranslations          : "channels/privacyPolicyTranslations",
      isLoadingPrivacyPolicyTranslations : "channels/isLoadingPrivacyPolicyTranslations",
      showPrivacyStatement               : "channels/showPrivacyStatement"
    }),
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    },
    isPrivacyPolicyEnabled() {
      return this.channel.privacyPolicy
    },
    privacyPolicyLink() {
      return this.channel.privacyPolicyLink
    },
    privacyPolicyDisplayName() {
      return this.channel.privacyPolicyDisplayName
    },
    speakUpLogo() {
      if (this.isMobileView) {
        return SpeakUpLogo
      }
      return SpeakUpLogoWithText
    },
    channel() {
      return this.channels.find(channel => channel.name === this.$route.params.channel)
    },
    formValues() {
      return this.reportFormValues[this.report?.id]
    },
    languagesMap() {
      const languagesMap = new Object()
      for (const language of this.languages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    privacyPolicyTranslatedText() {
      let privacyPolicyTranslationText = this.channel.privacyPolicyText
      if(this.privacyPolicyTranslations) {
        const language = this.languages.find(
          language => language.shortName === this.$i18n.locale
        )
        if (language) {
          const translatedText = this.privacyPolicyTranslations[language.id]

          if (translatedText && translatedText !== "") {
            privacyPolicyTranslationText = translatedText
          }
        }
      }
      return privacyPolicyTranslationText
    },
    showReturnButton() {
      if (this.$route.meta.return) {
        return this.$route.name !== "message" || this.formValues?.length
      }
      return false
    },
    privacyStatementLink() {
      return `${process.env.VUE_APP_PRIVACY_STATEMENT_BASE_URL}${this.$route.params.locale}`
    },
    isAuthPublic() {
      return this.$route.name === "languages" || this.$route.name === "report"
        || this.$route.name === "organisation-code" || this.$route.name === "consent"
    }
  },
  methods: {
    ...mapActions({
      suppressNotification: "shared/suppressNotification",
      removeMessage       : "messages/removeMessage",
      addSurvey           : "survey/addSurvey"
    }),
    ...mapMutations({
      setReporterLanguage    : "auth/setReporterLanguage",
      setShowPrivacyStatement: "channels/setShowPrivacyStatement"
    }),
    computeChannelLanguages() {
      this.channelLanguages                      = []
      let defaultLanguage                        = undefined
      const browserLanguageCode                  = navigator.language.split("-")[0]
      const matchingBrowserLanguageCodeLanguages = []
      let matchingBrowserLanguageCode
      let matchingBrowserLanguage
      let languageFromRouteParams
      let reportLanguage

      if (this.translationConfigurationsOfChannels[this.channel?.id]) {
        for (const translationConfiguration of this.translationConfigurationsOfChannels[this.channel.id]) {
          const language = this.languagesMap[translationConfiguration.languageId]
          if (language) {
            this.channelLanguages.push(language)
            if (this.report?.languageId === language.id) {
              reportLanguage = language
            }
            if (language.shortName === this.$CONSTANTS.DEFAULT_LANGUAGE.shortName) {
              defaultLanguage = language
            }
            if (navigator.language.toLowerCase() === language.shortName.toLowerCase()) {
              matchingBrowserLanguage = language
            } else if (language.shortName === browserLanguageCode) {
              matchingBrowserLanguageCode = language
            } else if (language.shortName.startsWith(browserLanguageCode)) {
              matchingBrowserLanguageCodeLanguages.push(language)
            }
            if (language.shortName === this.$route.params.locale) {
              languageFromRouteParams = language
            }
          }
        }
        if (this.channelLanguages.length) {
          if (reportLanguage) {
            this.changeReporterLanguage(reportLanguage)
          } else if (languageFromRouteParams) {
            this.changeReporterLanguage(languageFromRouteParams)
          } else if (matchingBrowserLanguage) {
            this.changeReporterLanguage(matchingBrowserLanguage)
          } else if (matchingBrowserLanguageCode) {
            this.changeReporterLanguage(matchingBrowserLanguageCode)
          } else if (matchingBrowserLanguageCodeLanguages.length) {
            this.changeReporterLanguage(matchingBrowserLanguageCodeLanguages[0])
          } else if (defaultLanguage) {
            this.changeReporterLanguage(defaultLanguage)
          } else {
            this.changeReporterLanguage(this.channelLanguages[0])
          }
        }
      }
    },
    changeReporterLanguage(language) {
      this.showLanguagesDialog = false
      this.setReporterLanguage(language)
      if (language.shortName !== this.$route.params.locale) {
        this.$router.push({
          name  : this.$route.name,
          params: {
            locale: language.shortName
          }
        })
      }
    },
    handleReturn() {
      if (this.$route.name === "message") {
        this.showDiscardMessageDialog = true
      } else {
        this.$router.push({
          name: this.$route.meta.return
        })
      }
    },
    discardMessage() {
      this.removeMessage(this.$route.params.messageId)
    },
    logout(surveyResponses) {
      if (!this.surveySent && surveyResponses) {
        this.addSurvey({ surveyResponses, isAuthPublic: this.isAuthPublic })
      }
      this.showSurveyForm       = false
      this.surveySent           = false
      this.showEndSessionDialog = false
      this.showSurvey           = false
      this.$router.push({
        name  : "logout",
        params: {
          locale : this.$route.params.locale,
          channel: this.$route.params.channel
        }
      })
    },
    submitSurveyResponse(surveyResponses) {
      this.addSurvey({ surveyResponses, isAuthPublic: this.isAuthPublic })
      this.surveySent = true
    },
    handleOnClickLogout() {
      if ((this.report?.status === "draft" || this.$route.name === "languages"
        || this.$route.name === "acknowledgement" || this.$route.name === "verify-email"
        || this.$route.name === "email-verified" || this.$route.name === "consent" || this.$route.name === "organisation-code")
        && this.showReporterFeedback
      ) {
        this.showSurvey = true
      } else {
        this.showEndSessionDialog = true
      }
    },
    handlePrivacyStatement(value) {
      this.setShowPrivacyStatement(value)
    }
  },
  watch: {
    isRemovingMessage: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.DISCARD_MESSAGE.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.DISCARD_MESSAGE.pActions[0].buttonProps.disabled = newValue
      }
    },
    languagesMap: {
      immediate: true,
      handler  : function() {
        this.computeChannelLanguages()
      }
    },
    translationConfigurationsOfChannels: {
      immediate: true,
      handler  : function() {
        this.computeChannelLanguages()
      }
    },
    reporterLanguage: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          if (!this.$route.query.microcopy) {
            this.$i18n.locale = newValue.shortName
            this.$vuetify.rtl = this.$CONSTANTS.RTL_LANGUAGES.includes(newValue.shortName)
          }
        } else {
          this.computeChannelLanguages()
        }
      }
    },
    isMessageRemoved: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.showDiscardMessageDialog = false,
          this.$router.push({
            name  : "intake",
            params: {
              locale : this.$route.params.locale,
              channel: this.$route.params.channel
            }
          })
        }
      }
    }
  }
}
